<template>
    <section>
        <div class="menu_container row">
            <div class="col-10 mx-auto d-flex flex-wrap " style="row-gap: 4rem; column-gap: 3rem">
                <div v-for="(menu,i) in data" :key="i">      
                    <div v-if="menu.id_menu_superior == null" class="card card__size card__size cr-pointer position-relative" @click="redirect(menu.id)">
                        <div class="icon__center icon__color h-100">
                            <i :class="`icon-${menu.icono} f-40`"></i>
                        </div>
                        <div class="text-bottom">{{menu.nombre}}</div>
                    </div>          
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
    components:{
    },
    data() {
      return {
        idFirstSubMenu: null,
        data: null
      }
    },
    mounted(){
    },
    computed: {
        ...mapGetters({
            menu: 'configurar/permisos/menus/menus',
            user_data: 'auth/user',
        }),
        menus: {
           get(){
             return this.menu
           },
           set(newName){
             return newName
           } 
        }
    },
    watch: {
        id(val){
            console.log('consultar a la api ', val);
        },
        menu(){
            this.data = this.menu.filter(el => {
                return el.id_menu_superior == null
            })
            if (this.user_data.mostrar_configuracion == 1 && this.user_data.super_admin == 0) {
                this.data =  this.data.filter(el => {
                    const exists = this.user?.permiso_configuracion.find(e => `${e.nombre}`.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim() == `${el.nombre}`.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim())
                    if (exists) return el
                })
                // if (men.length) this.men.push(this.menus[5])
            }

        }
    },
    eliminarDiacriticosEs(str) {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    },
    async created(){
        await this.getMenus()
        this.getBreadcumbs([ 'configurar.permisos' ]);
    },
    methods:{
        redirect(id){
            this.getFirstSubMenu(id)
            this.$router.push({ name: 'configurar.permisos.acciones.menu', params: { id, tab: this.idFirstSubMenu }})
        },
        ...mapActions({
            getMenus: 'configurar/permisos/menus/Action_get_menus',
            getBreadcumbs: 'navigation/breadcumbs/getBreadcumbs',
        }),
        getFirstSubMenu(id) {
            const first = this.menus.find(el => el.id_menu_superior == id)
            this.idFirstSubMenu = first == null ? 0 : first.id
        }
    }
}
</script>

<style lang="scss" scoped>
.card__size{
    height: 100px; 
    width: 100px;
}
.icon__center{
    display: grid;
    place-items: center;
}
.icon__color{
    color: #5D5D5D;
}
.text-bottom{
    position: absolute;
    width: calc(100% + 10px);
    height: 25px;
    text-align: center;
    left: -5px;
    bottom: -30px;
    color: var(--text-general);
}
.card {
    word-wrap: none;
}
</style>